<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top">
                <h2><span>■</span> 내담자관리</h2>
                <div class="btns mb-30">
                    <a class="btn_new2 pointer" @click="register()">내담자등록</a>
                </div>
            </div>
            <div class="con">
                <div class="con_table">
                    

                    <div class="search2">
                        <p class="mb-10" style="color:red;font-size:14px;">※ 엑셀다운로드는 기간검색 Search를 누르고 실행해 주세요</p>
                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <div class="float-left dp-block ">
                            <select class="ml-10 w-200px h-30px" v-model="idxCrmCenter" @change="getPersonList()">
                                <option value="-1">센터선택</option>
                                <option value="0">본원센터</option>
                                <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                            </select>
                        </div>
                        <div class="float-left dp-block ml-20 mb-10">등록일기간검색 :
                            <crm-datepicker v-model="startDate" :parentDate="startDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
                            <crm-datepicker v-model="endDate" :parentDate="endDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            <input type="text" v-model="searchText" class="ml-10 w-200px h-30px pd-00 pl-20" placeholder="고객명/이메일/연락처">
                            <a class="btn_search ml-20 pointer" @click="getPersonList()">Search<span></span></a>
                        </div>
                        <button type="button" class="float-right down-btn ml-20 mb-20" @click="excelDown()">엑셀파일 다운로드</button>
                    </div>
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th class="w-70">고객명</th>
                                <th>성별</th>
                                <th>생년월일</th>
                                <th>이메일</th>
                                <th>연락처</th>
                                <th>담당상담사</th>
                                <th>유입경로</th>
                                <th>작성자</th>
                                <th>등록일</th>
                                <th>관리</th>
                            </tr>
                            <tr>
                                <td colspan="10">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>No</th>
                                <th class="w-70">고객명</th>
                                <th>성별</th>
                                <th>생년월일</th>
                                <th>이메일</th>
                                <th>연락처</th>
                                <th>담당상담사</th>
                                <th>유입경로</th>
                                <th>작성자</th>
                                <th>등록일</th>
                                <!-- <th>관리</th> -->
                            </tr>
                            <tr v-for="(item, index) of personList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td class="underline pointer blue" @click="modify(item.idx)">{{item.name}}</td>
                                <td>{{item.gender}}</td>
                                <td>{{item.birth}}</td>
                                <td>{{item.email}}</td>
                                <td>{{item.phone}}</td>
                                <td>
                                    <pre>{{item.staffName}}</pre>
                                </td>
                                <td>{{item.routeName}}</td>
                                <td>{{item.updateStaff}}</td>
                                <td>{{dateCheck(item.createDate)}}</td>
                                <!-- <td>
                                    <span class="underline pointer" @click="modify(item.idx)">수정</span>
                                </td> -->
                            </tr>
                            <tr v-if="personList.length === 0">
                                <td colspan="10">등록된 정보가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import FileSaver from 'file-saver'
export default {
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜
        value: '',
        searchText:'',
        personList: [],
        //centerList: [],
        idxCrmCenter: -1,
        status: '',
        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        loading:true,
        centerList:[],//센터리스트
    }),

    created(){
    },

    mounted() {

        /* this.getCenterList(), */
        if (sessionStorage.getItem('origin_person_pageNum') !== 'undefined' && sessionStorage.getItem('origin_person_pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('origin_person_pageNum'))
        }
        if (sessionStorage.getItem('origin_person_startDate') !== 'undefined' && sessionStorage.getItem('origin_person_startDate') !== null) {
            this.startDate = sessionStorage.getItem('origin_person_startDate')
        }
        if (sessionStorage.getItem('origin_person_endDate') !== 'undefined' && sessionStorage.getItem('origin_person_endDate') !== null) {
            this.endDate = sessionStorage.getItem('origin_person_endDate')
        }

        //this.startDate = this.formatDate(new Date())
        //this.endDate = this.formatDate(new Date() )
        this.getPersonList();
        this.getCenterListAll();

        //this.startDate = sessionStorage.getItem('origin_person_startDate')
        //this.endDate = sessionStorage.getItem('origin_person_endDate')
    },

    methods: {

        excelDown() {
            let params = {
                startDate: this.formatDate(this.startDate),
                endDate: this.formatDate(this.endDate)
            }

            let headers = {
                //'Content-Disposition': "attachment; filename=" + fileName + ".xlsx",
                'Content-Type': 'ms-vnd/excel;charset=UTF-8'
            }

            this.axios.defaults.headers.post[headers]

            this.axios.get('/api/v1/excel/person-list', {
                params: params,
                responseType: 'arraybuffer'
            }).then(res => {
                FileSaver.saveAs(new Blob([res.data]), "centerPerson_excel_template.xlsx")
            }).catch(err => {
                console.log(err)
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            })

        },

        dateCheck(date) {
            if (date !== null) {
                return date.slice(0, 10)
            } else {
                return '-'
            }
        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getPersonList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getPersonList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getPersonList()
            }
        },

        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate(date) {
            if (date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            } else if (date === '') {
                return ''
            } else {
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            }
        },

        // 고객 목록 조회 api
        getPersonList() {

            var params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                startDate: this.startDate,
                endDate: this.endDate,
                idxCrmCenter: this.idxCrmCenter,
                value:this.searchText,
            }

            sessionStorage.setItem('origin_person_pageNum', this.pageNum)
            sessionStorage.setItem('origin_person_startDate', this.formatDate(this.startDate))
            sessionStorage.setItem('origin_person_endDate', this.formatDate(this.endDate))
            this.loading = true;

            this.axios.get('/api/v1/client/person/list', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.personList = res.data.personList

                        if (res.data.personListCount) {
                            this.listLength = res.data.personListCount
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)
                    } else {
                        //alert('검색 결과가 없습니다.')
                        this.personList = []
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
                .finally(()=>{
                    this.loading = false;
                })
        },

        // 고객차트 페이지로 이동
        detailChart(idx) {
            this.$router.push(`/origin/customer_manage_chart?idx=${idx}`)
        },
        // 고객 등록페이지로 이동
        register() {
            this.$router.push('/origin/person_manage_register')
        },
        // 고객 수정페이지로 이동
        modify(idx) {
            this.$router.push(`/origin/person_manage_modify?idx=${idx}`)
        },
        // 상담예약관리 상세정보 페이지로 이동
        detail() {
            this.$router.push('/origin/consult_reserve_detail')
        },

        // 고객 차트
        client(idx) {
            this.$router.push(`/origin/customer_manage_chart?idx=${idx}`)
        },

        /**
         * @description : 센터 리스트 추출
         */
        getCenterListAll(){
            this.axios.get('/api/v1/center/all', {})
                .then(res =>{
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList;
                    }else{
                        this.centerList = [];
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        }
    }
}
</script>